<template>
    <div>
        <!-- <div class="right-btns" v-if="viewer.depth > 0" >
            <span @click="doFolderBack">返回上一级</span>
        </div> -->
        <div v-if="viewer.showFiles.length > 0">
            <el-row :gutter="20" v-if="favoriteTabNum == 2">
                <el-col :span="5" style="margin-bottom: 15px;" v-for="cardItem in viewer.showFiles" :key="cardItem.id">   
                    <el-card class="favorite-card" :body-style="{ padding: '0px' }" @click.native="handleRowClickEvent(cardItem)">
                        <div class="document-top">
                            <!-- 图片展示 -->
                            <img :src="coverImg(cardItem)" v-if='cardItem.type==0' style="width:80px;height:70px;margin-top:65px" alt="cardItem.name" lazy>
                            <img :src="coverImg(cardItem)" v-else style="max-height: 100%;width:100%"  alt="cardItem.name" lazy>
                        </div>
                        <div class="document-middle">
                            <span :title="cardItem.documentName || cardItem.name">{{cardItem.documentName || cardItem.name}}</span>
                            <div>
                                <span>
                                    <i class="el-icon-user-solid"></i>
                                    {{cardItem.ownerName}}
                                </span>
                                <span>{{ cardItem.type == 0 ? '文件夹' : '文件'}}</span>
                            </div>
                            <div>
                                <span>{{cardItem.createDate | date}}</span>
                                <i class="el-icon-star-on" title="取消收藏" v-show="!cardItem.parentId"  @click.stop="removeStarList(cardItem)"></i>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-table
            v-if="favoriteTabNum == 1 && viewer.showFiles.length != 0"
            :data="viewer.showFiles"
            size="small"
            height="calc(100vh - 265px)"
            style="width: 100%;"
           >
                <!-- <el-table-column
                type="selection"
                align="center"
                width="50">
                </el-table-column> -->
                <el-table-column
                    prop="name"
                    label="文件名称"
                    width="500">
                    <template slot-scope="scope">
                       <div class="start-file-box" @click="handleRowClickEvent(scope.row)">
                            <img :src="thumbIcon(scope.row)" alt="" :class="{'doc-pic' : scope.row.type == 0}">
                            <span>{{scope.row.documentName || scope.row.name}}</span>
                       </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="文件类型"
                    align="center">
                    <template slot-scope="scope">
                        {{ scope.row.type == 0 ? '文件夹' : '文件'}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="ownerName"
                    label="所有者"
                    align="center">
                </el-table-column>
                <el-table-column
                    label="创建日期"
                    align="center">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-show="!scope.row.parentId" size="small" @click.stop="removeStarList(scope.row)">
                            取消收藏
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
            class="pagebox"
            @current-change="handleCurrentChange"
            :current-page="formLine.pageNum"
            :page-size="formLine.pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <div class="no-data-box" v-if=' viewer.showFiles.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>
   
    </div>
</template>
<script>
import {shareAPI} from 'liankong-ui-api' 
import iconFolder from '@/assets/img/folder.png'
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'

import defaultImg from '@/assets/folder.png'
import morenImg from '@/assets/moren.png'

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  folder: iconFolder,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

    mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}
export default {
    data () {
        return {
            viewer:{
                showFiles:[]
            },
            viewerKey: 'my',
            formLine: {
                name: '',
                pageNum: 1,
                pageSize: 10
            },
            count: 0, // 计算进入目录数
            favoriteTabNum: 1
        }
    },
    computed: {
        total(){
            return this.$store.state.collection.paging.total
        },
        coverImg(){
            return (doc) => doc.type == 1 ?(doc.coverImg!=null? this.$options.filters["thumbnailUrl"](doc.coverImg):morenImg ): defaultImg
        },
    },
    mounted(){
        this.onLoadStarPage();

        this.$ebus.$on('startGoBackBtn', text=>{
            this.doFolderBack()
        })
        this.$ebus.$on('favoriteSearch', fileName=>{
            this.formLine.name = fileName;
            this.onLoadStarPage();
        })
        this.$ebus.$on('clearSearch', vals=>{
            this.formLine.name = '';
            this.viewer.depth = 0;
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
            this.onLoadStarPage();
        })
        this.$ebus.$on('favoriteTabEvent', favoriteTabNum=>{
            this.favoriteTabNum = favoriteTabNum;
        })
        this.$ebus.$on('clickMyStart', item=>{
            if(item.title == '我的收藏') {
                this.onLoadStarPage();
                this.viewer.depth = 0;
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
            } else {
                if (item.data.type == 0) {
                    this.$store.dispatch("doc/enterFolder", {
                        vkey: this.viewerKey,
                        folder: item.data
                    });
                    this.viewer = this.$store.state.doc.viewer[this.viewerKey];
                }
                if(item.data.children.length > 0) {
                    this.$store.commit('folderBreadDeleteNum', {id: item.path, vkey: 'my'})
                }
            }
        })
    },
    methods: {
         /**
         * 图标
         */
        thumbIcon(doc){
            let iconKey = "unknown"
            if(doc.type == 0){
                iconKey = "folder"
            }else if(suffixIconCache.hasOwnProperty(doc.suffix)){
                iconKey = doc.suffix
            }
            return suffixIconCache[iconKey] 
        },
        onLoadStarPage(){
            this.$store.dispatch("collection/dollectionList", {reset:true, query: this.formLine ,isPc:true}).then(res=>{
                this.viewer.showFiles = this.$store.state.collection.dollectionList;
            })
        },
        removeStarList(row) {
            this.$confirm('确认取消收藏所选文件吗？', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(res=>{
                this.$store.dispatch("collection/removeDollection", row.id).then((code)=>{
                    if(code){
                        this.onLoadStarPage();
                        this.$message.success("取消收藏成功"); 
                    }else{
                        this.$message.error("取消收藏失败"); 
                    }
                }).catch((error)=>{
                    this.$message.error("取消收藏失败");
                })
            }).catch(error=>{
            })
        },
        handleRowClickEvent(row){
            if (row.type == 0) {
                if(row.documentId != null){
                    row.id = row.documentId
                }
                //打开文件夹
                this.handleEnterFolder(row);
            } else {
                // 打开阅读器
                if(row.documentId != null){
                    row.id = row.documentId
                }

                this.$ebus.$emit('publicReader',{
                    shareId:row.shareId, 
                    id:row.documentId || row.id,
                    documentId: row.documentId,
                    name:row.name,
                    suffix:row.suffix
                })
               
            }
        },
        //进入指定目录
        handleEnterFolder(folder) {
            const isFolderExit = this.$store.state.folderBreadList.some(item=>item.title==folder.documentName)

            if(!isFolderExit){
                this.count++;
            // 文件夹的名称存起来
                if(folder.name == undefined) {
                    folder.name = folder.documentName
                }
                    this.$store.commit('folderBreadList', {doc: folder, tip: '我的收藏'});
                if (folder) {
                        if (folder.type == 0) {
                            this.$store.dispatch("doc/enterFolder", {
                                vkey: this.viewerKey,
                                folder
                            });
                            this.viewer = this.$store.state.doc.viewer[this.viewerKey];
                        }
                    } else {
                        this.$store.dispatch("doc/enterFolder");
                    }

                if(this.viewer.depth > 0) {
                    this.$store.commit('pageBackTextShow', '我的收藏')
                }
            } else {
                this.$store.dispatch("doc/loadChildren",{
                    vkey: this.viewerKey,
                    reset:true,
                    pageSize:10
                });
                
            }
        },
        // 返回上一级 后退
        doFolderBack(){
            this.count--;
            this.$store.dispatch("doc/backFolder", {
                vkey: this.viewerKey,
            }).then(res=>{
                if(this.count == 0) {
                    this.viewer.showFiles = this.$store.state.collection.dollectionList;
                } else if(this.viewer.depth == 0) {
                    this.viewer.showFiles = this.$store.state.collection.dollectionList;
                }
            })

            if(this.viewer.depth == 0) {
                this.$store.commit('pageBackTextShow', '')
            }

            this.$store.commit('backFolerBread')
        },
        // 分页
        handleCurrentChange(num){
            this.formLine.pageNum = num;
            this.onLoadStarPage();
        }
    },
    beforeDestroy() {
        this.$ebus.$off('startGoBackBtn');
        this.$ebus.$off('favoriteSearch');
        this.$ebus.$off('favoriteTabEvent');
        this.$ebus.$off('clearSearch');
        this.$ebus.$off('clickMyStart')
    }
}
</script>
<style lang="less" scoped>
.favorite-card {
    height: 300px;
    padding: 10px;
    box-sizing: border-box;
    .document-top {
        height: 200px;
        line-height: 200px;
        text-align: center;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .document-middle {
        margin: 10px;
        >span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
        }
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin: 5px 0 0 0;
            .el-icon-star-on {
                color: #409EFF;
                font-size: 16px;
            }
        }
    }
}
.right-btns {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: #666666;
    cursor: pointer;
}
/deep/ .el-table {
    .start-file-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
        span {
            display: block;
            width: 450px; 
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
        .doc-pic {
            width: 28px;
            height: 28px;
            margin-bottom: 5px;
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.pagebox {
    margin-top: 20px;
    text-align: left;
}
.el-row {
    height:calc(100vh - 265px);
    overflow: auto;
    .el-col-5 {
        width: 19.83%;
    }
}
.no-data-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-right: 10%;
    img {
        width: 380px;
        height: 280px;
    }
    span {
        color: #bdc1cd;
        font-size: 16px;
        margin-top: 15px;
        font-weight: 100px;
    }
}
</style>

